import { Category } from '@commerce/types/site'
import { NormalizedMenuTaxon } from '@models'

type PrepareParamsType = {
  q: string | string[] | undefined
  activeCategory?: Category | undefined
  minPriceState?: string
  maxPriceState?: string
  pageState?: string
  taxon?: string | string[]
  sortState?: string | string[]
}
export const PRICE_FILTER_VALUES = [
  ['0', '25'],
  ['25', '50'],
  ['50', '75'],
  ['75', '125'],
  ['125', '250'],
  ['250', ''],
]

export const prepareParams = ({
  q,
  minPriceState,
  maxPriceState,
  pageState,
  taxon,
  sortState,
}: PrepareParamsType) =>
  new URLSearchParams({
    ...(q ? { q: typeof q === 'string' ? q : '' } : {}),
    ...(minPriceState ? { minPrice: minPriceState.toString() } : {}),
    ...(maxPriceState ? { maxPrice: maxPriceState.toString() } : {}),
    ...(pageState ? { page: pageState } : {}),
    ...(taxon ? { taxon: taxon.toString() } : {}),
    ...(sortState ? { sort: sortState.toString() } : {}),
  }).toString()

export const getTaxonId = (
  path: string,
  taxons?: NormalizedMenuTaxon[],
): {
  taxon?: string
  taxonObj?: NormalizedMenuTaxon
} => {
  if (!taxons)
    return {
      taxon: undefined,
      taxonObj: {} as NormalizedMenuTaxon,
    }
  const pathSplitted = path.split('?')[0].split('/')
  const taxon = taxons.find(taxon => {
    const taxonHrefSplitted = taxon.href.split('/')

    return taxonHrefSplitted.slice(-1)[0] === pathSplitted.slice(-1)[0]
  })

  return {
    taxon: taxon?.id,
    taxonObj: taxon,
  }
}
