export const ArrowDownIconCopperStudio = (props: any) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='31'
    height='30'
    viewBox='0 0 31 30'
    fill='none'
    {...props}
  >
    <g clipPath='url(#clip0_8861_655)'>
      <path
        d='M9.7625 10.7375L15.5 16.4625L21.2375 10.7375L23 12.5L15.5 20L8 12.5L9.7625 10.7375Z'
        fill='#898984'
      />
    </g>
    <defs>
      <clipPath id='clip0_8861_655'>
        <rect width='30' height='30' fill='white' transform='translate(0.5)' />
      </clipPath>
    </defs>
  </svg>
)
