import { Dispatch, SetStateAction } from 'react'
import cn from 'classnames'
import { Icon } from '@components/common'
import { ArrowLeftSmall } from '@components/icons/ArrowLeft'
import { Button } from '@components/ui'
import { useBrandThemeContext } from '@contexts/brandThemeContext'
import { PriceFilter, PriceFilterValue } from './PriceFilter'
import s from './Store.module.css'

interface IFilterPanelProps {
  setShowFilters: Dispatch<SetStateAction<boolean>>
  handleClearAllFilters: () => void
  priceFilter: PriceFilterValue
  handleSetPriceFilter: (value: PriceFilterValue) => void
  isMaxPriceInvalid: boolean
}

export const FiltersPanel = ({
  priceFilter,
  isMaxPriceInvalid,
  setShowFilters,
  handleClearAllFilters,
  handleSetPriceFilter,
}: IFilterPanelProps) => {
  const {
    filter_section_border: filterSectionBorder,
    filters_background_color: filtersBackroundColor,
    filter_section_shadow: filterSectionShadow,
  } = useBrandThemeContext()
  const hideFilterSectionBorders = filterSectionBorder === 'none'
  const isPriceFilterApplied = [
    priceFilter.maxPrice,
    priceFilter.minPrice,
  ].some(price => price)

  return (
    <div
      className={cn(s.filtersSidebar, {
        [s.borderAround]: filterSectionBorder === 'around',
        [s.borderRight]: filterSectionBorder === 'right',
        [s.borderNone]: hideFilterSectionBorders,
        [s.shadowAround]: filterSectionShadow !== 'none',
        'bg-filtersBackground': filtersBackroundColor !== 'none',
      })}
    >
      <div className='w-full flex justify-between items-baseline'>
        <div className='flex items-center'>
          <button
            className='mr-6 sm:hidden'
            type='button'
            onClick={() => setShowFilters(prev => !prev)}
          >
            {' '}
            <Icon
              set1={<ArrowLeftSmall />}
              set2={<ArrowLeftSmall />}
              set3={<ArrowLeftSmall />}
              setNinefit={<ArrowLeftSmall />}
              setNinelife={<ArrowLeftSmall />}
            />
          </button>
          <h2 className='font-bold text-xl sm:text-base'>Filter by Price</h2>
        </div>
        <Button
          disabled={!isPriceFilterApplied}
          variant='clear'
          className='!text-clearFilter'
          onClick={handleClearAllFilters}
        >
          Clear
        </Button>
      </div>
      <div
        className={cn({
          [s.filtersDelimiter]: !hideFilterSectionBorders,
          'my-2': hideFilterSectionBorders,
        })}
      />
      <PriceFilter
        minPrice={priceFilter.minPrice || ''}
        maxPrice={priceFilter.maxPrice || ''}
        handleSetPriceFilter={handleSetPriceFilter}
        isPriceFilterApplied={isPriceFilterApplied}
        isMaxPriceInvalid={isMaxPriceInvalid}
      />
      <button
        className='my-5 mr-6 sm:hidden flex items-center'
        type='button'
        onClick={() => setShowFilters(prev => !prev)}
      >
        {' '}
        <Icon
          set1={<ArrowLeftSmall />}
          set2={<ArrowLeftSmall />}
          set3={<ArrowLeftSmall />}
          setNinefit={<ArrowLeftSmall />}
          setNinelife={<ArrowLeftSmall />}
        />{' '}
        <span className='ml-4 text-brand text-[16px]'>Return</span>
      </button>
    </div>
  )
}
