import { useStoreDataContext } from '@contexts/storeDataContext/useStoreDataContext'
import { Icon } from '@components/common'
import { Cross } from '@components/icons'
import { PriceFilterValue, rangePriceCheckboxLabel } from '../PriceFilter'

export const SelectedFilterPriceBox = ({
  priceFilter,
  handleClearAllFilters,
}: {
  priceFilter: PriceFilterValue
  handleClearAllFilters: () => void
}) => {
  const {
    data: {
      attributes: {
        currency_format: currencyFormat,
        decimal_mark: decimalMark,
        thousands_separator: thousandsSeparator,
        default_currency: defaultCurrency,
        currency_symbol: currencySymbol,
      },
    },
  } = useStoreDataContext()

  return (
    <div className='hidden whitespace-nowrap sm:flex justify-between h-12  items-center sm:border rounded-brand px-5 py-3  sm:font-normal sm:text-base sm:gap-x-2 transition ease-in-out duration-150 border-sortFilterColor border-2 text-lg font-bold text-sortFilterColor'>
      {rangePriceCheckboxLabel(
        [priceFilter.minPrice || '', priceFilter.maxPrice || ''],
        currencyFormat,
        decimalMark,
        thousandsSeparator,
        defaultCurrency,
        currencySymbol,
      )}
      <button type='button' onClick={handleClearAllFilters}>
        <Icon
          set1={<Cross />}
          set2={<Cross />}
          set3={<Cross />}
          setNinefit={<Cross />}
          setNinelife={<Cross />}
          setCopperStudio={<Cross />}
        />
      </button>
    </div>
  )
}
