import cn from 'classnames'
import { FC, MutableRefObject, useRef, useState } from 'react'
import ClickOutside from '@lib/click-outside'
import { ArrowDown } from '@components/icons'
import { ArrowDownSecond } from '@components/iconsSetTwo'
import { ArrowDownThird } from '@components/iconsSetThree'
import { Icon } from '@components/common'
import { SortIcon } from '@components/icons/SortIcon'
import { ArrowDownIconCopperStudio } from '@components/iconSetCopperStudio/ArrowDownIconCopperStudio'
import s from './SortDropdownMenu.module.css'

interface SortDropdownMenuProps {
  sort: string
  handleClick: (value: string) => void
  disabled?: boolean
  isHomeBrand?: boolean
}

const SortDropdownMenu: FC<SortDropdownMenuProps> = ({
  sort,
  handleClick,
  disabled,
  isHomeBrand,
}) => {
  const [display, setDisplay] = useState(false)
  const ref = useRef() as MutableRefObject<HTMLDivElement>

  const sortOptions = [
    { value: 'rel', label: 'Relevance' },
    { value: 'price-asc', label: 'Price: Low to high' },
    { value: 'price-desc', label: 'Price: High to low' },
    { value: 'bestsellers', label: isHomeBrand ? 'Trending' : 'Bestsellers' },
  ]

  return (
    <div
      className={cn(
        'sm:flex sm:items-center sm:justify-between text-sm z-30 mb-2 xs:sm-0',
        { 'flex items-center justify-between': isHomeBrand },
      )}
    >
      <span
        className={cn({
          'sm:inline-block hidden whitespace-nowrap': !isHomeBrand,
          'inline-block font-bold md:font-normal': isHomeBrand,
        })}
      >
        Sort by
      </span>
      <ClickOutside active={display} onClick={() => setDisplay(false)}>
        <div
          className={cn('flex flex-col relative sm:flex-1 sm:ml-3 h-12', {
            'flex-1 ml-3': isHomeBrand,
          })}
        >
          <button
            type='button'
            disabled={disabled}
            onClick={() => {
              setDisplay(!display)
            }}
            aria-label='Menu'
            className={cn(
              'flex justify-between h-14  items-center sm:border rounded-brand sm:w-full px-8 py-3  sm:font-normal sm:text-base sm:gap-x-7 transition ease-in-out duration-150 sm:pl-3.5 sm:pr-0',
              s.sortDropdownBtn,
              {
                'border-sortFilterColor border-2 text-lg font-bold text-sortFilterColor':
                  !isHomeBrand,
                'font-normal text-base border-base-border border pl-3.5 pr-0 gap-x-7 w-full':
                  isHomeBrand,
              },
            )}
            id='options-menu'
            aria-haspopup='true'
            aria-expanded='true'
          >
            {!isHomeBrand ? (
              <>
                <Icon
                  set1={<SortIcon className='mr-1 sm:hidden' />}
                  set2={<SortIcon className='mr-1 sm:hidden' />}
                  set3={<SortIcon className='mr-1 sm:hidden' />}
                  setNinelife={<SortIcon className='mr-1 sm:hidden' />}
                  setNinefit={<SortIcon className='mr-1 sm:hidden' />}
                  setCopperStudio={<SortIcon className='mr-1 sm:hidden' />}
                />
                <span className='inline-block sm:hidden'>Sort by</span>
              </>
            ) : null}
            <div className={cn({ 'sm:inline-block hidden': !isHomeBrand })}>
              {sortOptions.find(option => option.value === sort)?.label || (
                <>
                  <span
                    className={cn({
                      'sm:inline-block hidden': !isHomeBrand,
                      'inline-block': isHomeBrand,
                    })}
                  >
                    Relevance
                  </span>
                  {!isHomeBrand && (
                    <span className='inline-block sm:hidden'>Sort by</span>
                  )}
                </>
              )}
            </div>
            <div
              className={cn(
                'h-12 w-12 border-l border-base-border  items-center justify-center text-grayText ',
                {
                  hidden: !isHomeBrand,
                  'sm:flex': !isHomeBrand,
                  flex: isHomeBrand,
                },
              )}
            >
              <Icon
                set1={
                  <ArrowDown
                    className='text-grayText'
                    style={{ transform: `rotate(${display ? 180 : 0}deg)` }}
                  />
                }
                set2={
                  <ArrowDownSecond
                    className='h-3 w-3 my-auto mr-1 text-black'
                    style={{ transform: `rotate(${display ? 180 : 0}deg)` }}
                  />
                }
                set3={
                  <ArrowDownThird
                    className='my-auto text-black'
                    style={{ transform: `rotate(${display ? 180 : 0}deg)` }}
                  />
                }
                setNinelife={
                  <ArrowDown
                    className='text-grayText'
                    style={{ transform: `rotate(${display ? 180 : 0}deg)` }}
                  />
                }
                setNinefit={
                  <ArrowDown
                    className='text-grayText'
                    style={{ transform: `rotate(${display ? 180 : 0}deg)` }}
                  />
                }
                setCopperStudio={
                  <ArrowDownIconCopperStudio
                    className='text-grayText'
                    style={{ transform: `rotate(${display ? 180 : 0}deg)` }}
                  />
                }
              />
            </div>
          </button>

          {display && (
            <div className={s.sortDropdownMenu} ref={ref}>
              {sortOptions.map(option => (
                <span
                  aria-hidden
                  key={option.value}
                  onClick={() => {
                    handleClick(option.value)
                    setDisplay(!display)
                  }}
                  className={cn(s.sortDropdownLink, {
                    ' cursor-default font-bold text-black hover:text-black':
                      sort === option.value,
                    ' cursor-default text-gray-600 hover:text-black hover:bg-gray-100':
                      sort !== option.value,
                  })}
                >
                  {option.label}
                </span>
              ))}
            </div>
          )}
        </div>
      </ClickOutside>
    </div>
  )
}

export default SortDropdownMenu
