export const ArrowDownSecond = ({ ...props }) => (
  <svg
    role='img'
    width='16'
    height='11'
    viewBox='0 0 16 11'
    fill='currentColor'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <title>Arrow Down Icon</title>
    <path d='M1.88 0.453339L8 6.56001L14.12 0.453339L16 2.33334L8 10.3333L0 2.33334L1.88 0.453339Z' />
  </svg>
)
