import cn from 'classnames'
import React, { FC } from 'react'

interface BoxProps {
  className?: string,
  children?: any,
  el?: HTMLElement,
  wrapper?: boolean,
  main?: boolean,
  sidebar?: boolean,
  productWrapper?: boolean,
}

const Box: FC<BoxProps> = ({
  children,
  className,
  el = 'div',
  wrapper,
  main,
  sidebar,
  productWrapper,
}) => {
  const rootClassName = cn(className, {
    'flex w-full flex-col lg:flex-row': wrapper,
    'flex w-full flex-col lg:pl-7 lg:w-3/4': main,
    'flex w-full flex-col lg:w-1/4': sidebar,
    'flex min-w-full flex-col xl:px-20 xl:-mx-20': productWrapper,
  })

  const Component: React.ComponentType<React.HTMLAttributes<HTMLDivElement>> =
    el as any

  return <Component className={rootClassName}>{children}</Component>
}

export default Box
