export const SubcategoriesArrowLeft = ({ ...props }) => (
  <svg
    width='17'
    height='9'
    viewBox='0 0 17 9'
    fill='currentColor'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path d='M4.49 5.30005L16.5 5.30005L16.5 3.30005L4.49 3.30005L4.49 0.300048L0.5 4.30005L4.49 8.30005L4.49 5.30005Z' />
  </svg>
)
