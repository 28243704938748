import { toast } from 'react-toastify'
import {
  useState,
  useCallback,
  useReducer,
  useRef,
  useEffect,
  useMemo,
} from 'react'
import { useRouter } from 'next/router'
import cn from 'classnames'

import { getProducts } from '@api/getProducts'
import { Box, Container } from '@components/ui'
import { Layout, SortDropdownMenu } from '@components/common'
import { SearchPropsType } from '@lib/search-props'
import { TOAST_OPTIONS } from '@constants/toastOptions'
import { BANNER_ELEMENT_TYPES, CmsPage, NormalizedMenuTaxon } from '@models'
import { ChevronRight, ChevronLeft } from '@components/icons'

import { useStoreDataContext } from '@contexts/storeDataContext/useStoreDataContext'
import { getCmsPages } from '@api/getCmsPages'
import {
  StoreActionTypes,
  initialStoreState,
  storeReducer,
} from './storeReducer'
import { StoreHeader } from './StoreHeader'
import { TaxonSelector } from './TaxonSelector/TaxonSelector'
import { FilterButton } from './FilterButton'
import { ProductsGrid } from './ProductsGrid'
import { StoreInfoHeader } from './StoreInfoHeader'
import { PriceFilterValue } from './PriceFilter'
import { FiltersPanel } from './FiltersPanel'
import { getTaxonId } from './utils'
import { FilterByPrice } from './FilterByPrice/FilterByPrice'
import { SelectedFilterPriceBox } from './FilterByPrice/SelectedFilterPriceBox'

const BRANDS_FILTER_DELIMITER = '=next='
const sortOptions = [
  { value: 'rel', label: 'Relevance' },
  { value: 'price-asc', label: 'Price: Low to high' },
  { value: 'price-desc', label: 'Price: High to low' },
  { value: 'bestsellers', label: 'Bestsellers' },
]

const NextLabel = ({ isHome }: { isHome: boolean }) => (
  <div
    className={cn('flex items-center gap-x-2.5', {
      'text-[#2A1A11] hover:text-white': isHome,
    })}
    tabIndex={0}
    aria-disabled
    role='button'
  >
    Next <ChevronRight className='h-5 w-5' strokeWidth={2} />
  </div>
)
const PrevLabel = ({ isHome }: { isHome: boolean }) => (
  <div
    className={cn('flex items-center gap-x-2.5', {
      'text-[#2A1A11] hover:text-white': isHome,
    })}
    tabIndex={0}
    aria-disabled
    role='button'
  >
    <ChevronLeft className='h-5 w-5' strokeWidth={2} /> Prev
  </div>
)

const Store = ({
  currentCategory,
  taxons,
  currentCollectionName,
  collectionKeyword,
  showTaxons,
}: SearchPropsType) => {
  const router = useRouter()
  const {
    data: {
      attributes: { is_home_brand: isHomeBrand },
    },
  } = useStoreDataContext()
  const {
    isReady,
    query: { collection, q, sort, page },
    asPath,
  } = router

  const [isLoading, setIsLoading] = useState(false)
  const [showFilters, setShowFilters] = useState(false)
  const [storeState, dispatch] = useReducer(storeReducer, initialStoreState)
  const [footerBanner, setFooterBanner] = useState<CmsPage | null>(null)

  const currentTaxon = taxons?.find(
    (taxon: NormalizedMenuTaxon) => taxon.id === currentCategory?.id,
  )

  const { taxon, taxonObj } = getTaxonId(asPath, currentTaxon?.dropDownItems)
  const prevTaxon = useRef(taxon)
  const prevCategory = useRef<string | null>()

  useEffect(() => {
    getCmsPages({ perPage: 1, page: 'homepage' }).then(data => {
      const pageBanners =
        (data?.included.map(el => ({
          attributes: {
            ...el.attributes,
            content: JSON.parse(JSON.stringify(el.attributes.content)),
          },
        })) as CmsPage[]) || []
      const banner = pageBanners.find(
        el =>
          el.attributes.content.banner_element ===
          BANNER_ELEMENT_TYPES.FOOTER_BANNER,
      )

      if (banner) {
        setFooterBanner(banner || null)
      }
    })
  }, [isHomeBrand])
  const isMaxPriceInvalid = useMemo(
    () =>
      storeState.priceFilter.minPrice !== '' &&
      storeState.priceFilter.maxPrice !== '' &&
      Number(storeState.priceFilter.minPrice || 0) >=
        Number(storeState.priceFilter.maxPrice || 0),
    [storeState.priceFilter.minPrice, storeState.priceFilter.maxPrice],
  )

  const handleClearAllFilters = () => {
    dispatch({
      type: StoreActionTypes.CLEAR_ALL_FILTERS,
      payload: {},
    })
  }

  if (sort && sort !== storeState.productsSort) {
    router.push(window.location.pathname, undefined, { shallow: true })
    dispatch({
      type: StoreActionTypes.CHANGE_PRODUCT_SORT,
      payload: { productsSort: String(sort) },
    })
  }
  if (
    (prevCategory.current !== null &&
      prevCategory.current !== currentCategory?.id) ||
    prevTaxon?.current !== taxon
  ) {
    prevCategory.current = currentCategory?.id || null
    prevTaxon.current = taxon
    handleClearAllFilters()
  }

  const handleGetProducts = useCallback(
    ({ currentPage = 1 }) => {
      setIsLoading(true)

      getProducts({
        collection_filter: collection ? collectionKeyword : '',
        search: q as string,
        categoryId: currentCategory?.id,
        sort: storeState.productsSort as string,
        minPrice: storeState.priceFilter?.minPrice,
        maxPrice: storeState.priceFilter?.maxPrice,
        brands: storeState.brandsFilter?.join(BRANDS_FILTER_DELIMITER),
        currentPage,
        taxon,
      })
        .then(res => {
          dispatch({
            type: StoreActionTypes.SAVE_PRODUCTS,
            payload: {
              products: res.products,
              productsMeta: {
                perPage: res.products.length,
                currentPage,
                totalPages: res.totalPages || 0,
                totalCount: res.totalCount || 0,
              },
            },
          })
          setIsLoading(false)
        })
        .catch(e => {
          if (e.name !== 'CanceledError') {
            toast.error(
              'Something went wrong. We can not get Products',
              TOAST_OPTIONS,
            )
            setIsLoading(false)
          } else {
            setIsLoading(true)
          }
        })
    },
    [
      currentCategory,
      storeState.brandsFilter,
      storeState.priceFilter,
      storeState.productsSort,
      q,
      taxon,
      collection,
      collectionKeyword,
    ],
  )

  const handleSortChange = useCallback((sortValue: string) => {
    dispatch({
      type: StoreActionTypes.CHANGE_PRODUCT_SORT,
      payload: {
        productsSort: sortValue,
      },
    })
  }, [])

  const handleSetPriceFilter = useCallback((value: PriceFilterValue) => {
    dispatch({
      type: StoreActionTypes.SET_PRICE_FILTER,
      payload: { priceFilter: value },
    })
  }, [])

  const onPageChange = (paginateData: { selected: number }) => {
    if (isMaxPriceInvalid) return
    router.replace(
      {
        query: { ...router.query, page: paginateData.selected + 1 },
      },
      undefined,
      { shallow: true },
    )
    window.scrollTo({ top: 0 })
  }

  useEffect(() => {
    if (isReady && !isMaxPriceInvalid) {
      handleGetProducts({ currentPage: page })
    }
  }, [
    page,
    storeState.priceFilter.minPrice,
    storeState.priceFilter.maxPrice,
    storeState.brandsFilter.length,
    storeState.productsSort,
    isMaxPriceInvalid,
    isReady,
    q,
    handleGetProducts,
  ])

  return (
    <>
      <Container common className={cn({ 'lg:!w-[908px]': isHomeBrand })}>
        <Box
          wrapper
          className={cn('sm:mt-12 mt-6 sm:mb-10 lg:flex-row-reverse')}
        >
          <Box main className={cn('flex flex-col lg:!pl-4 !w-full')}>
            {!showFilters && (
              <StoreHeader
                currentCategoryName={currentCategory?.name || ''}
                currentCollectionName={currentCollectionName || ''}
                description={currentCategory?.description}
                isHomeBrand={isHomeBrand}
              />
            )}
            {currentCategory && !showFilters && showTaxons && !isHomeBrand && (
              <TaxonSelector category={currentCategory} />
            )}

            {!showFilters && (
              <div
                className={cn(
                  'sm:flex flex-wrap flex-row-reverse w-full align-middle justify-between mb-5 sm:mb-4 relative',
                  { '!flex-row ': isHomeBrand, 'flex-nowrap': !isHomeBrand },
                )}
              >
                {isHomeBrand && (
                  <StoreInfoHeader
                    currentCategoryName={currentCategory?.name || ''}
                    productsPerPage={storeState.products?.length || 0}
                    productsTotalCount={storeState.productsMeta.totalCount}
                    isLoading={isLoading}
                    currentPage={Number(page) || 1}
                    totalPages={storeState.productsMeta.totalPages}
                    isHomeBrand={isHomeBrand}
                  />
                )}
                <div className='flex sm:block justify-between'>
                  <div className='flex gap-8'>
                    <SortDropdownMenu
                      isHomeBrand={isHomeBrand}
                      sort={storeState.productsSort as string}
                      handleClick={handleSortChange}
                      disabled={storeState.productsMeta.totalCount <= 0}
                    />
                    {!isHomeBrand && (
                      <FilterByPrice
                        priceFilter={storeState.priceFilter}
                        handleSetPriceFilter={handleSetPriceFilter}
                        isMaxPriceInvalid={isMaxPriceInvalid}
                      />
                    )}
                    {storeState.priceFilter.maxPrice ||
                    storeState.priceFilter.minPrice ? (
                      <SelectedFilterPriceBox
                        handleClearAllFilters={handleClearAllFilters}
                        priceFilter={storeState.priceFilter}
                      />
                    ) : null}
                  </div>
                  {!isHomeBrand && (
                    <FilterButton
                      onClick={() => setShowFilters(true)}
                      label='Filter'
                    />
                  )}
                </div>
                <div
                  className={cn('text-sm inline-block sm:hidden mt-1', {
                    hidden: isHomeBrand,
                  })}
                >
                  {sortOptions.find(
                    option => option.value === storeState.productsSort,
                  )?.label || <span>Relevance</span>}
                </div>
                {!isHomeBrand && (
                  <StoreInfoHeader
                    currentCategoryName={currentCategory?.name || ''}
                    productsPerPage={storeState.products?.length || 0}
                    productsTotalCount={storeState.productsMeta.totalCount}
                    isLoading={isLoading}
                    currentPage={Number(page) || 1}
                    totalPages={storeState.productsMeta.totalPages}
                  />
                )}
              </div>
            )}
            {!showFilters ? (
              <ProductsGrid
                onPageChange={onPageChange}
                nextLabel={<NextLabel isHome={isHomeBrand} />}
                prevLabel={<PrevLabel isHome={isHomeBrand} />}
                isLoading={isLoading}
                products={storeState.products}
                currentCategory={currentCategory}
                productsMeta={storeState.productsMeta}
              />
            ) : null}
          </Box>
          {!isHomeBrand && showFilters ? (
            <Box sidebar className='mb-7'>
              <FiltersPanel
                handleClearAllFilters={handleClearAllFilters}
                setShowFilters={setShowFilters}
                priceFilter={storeState.priceFilter}
                handleSetPriceFilter={handleSetPriceFilter}
                isMaxPriceInvalid={isMaxPriceInvalid}
              />
            </Box>
          ) : null}
        </Box>
      </Container>
      {footerBanner &&
      (taxonObj?.metaDescription || currentCategory?.metaDescription) ? (
        <div
          style={{
            background: `linear-gradient(0deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.50) 100%), url('${footerBanner.attributes.img_one_lg}'), lightgray 50% / cover no-repeat`,
            backgroundSize: 'cover',
            backgroundPosition: 'center center',
          }}
          className='px-10 sm:px-40 py-10 w-full text-white'
        >
          <div className='text-center font-bold text-sm max-w-[1240px] my-0 mx-auto'>
            {taxonObj?.metaDescription || currentCategory?.metaDescription}
          </div>
        </div>
      ) : null}
    </>
  )
}

Store.Layout = Layout
export default Store
