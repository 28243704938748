import { BaseSyntheticEvent, FC } from 'react'

import s from './CheckRadioField.module.css'

interface CheckRadioFieldProps {
  id: string
  checked: boolean
  onChange: (val: string) => void
  name?: string
  label?: React.ReactNode
  className?: string
  readOnly?: boolean
  disabled?: boolean
  invalid?: boolean
  error?: string
  ariaLabel?: string
}

export const CheckRadioField: FC<CheckRadioFieldProps> = ({
  id,
  name,
  checked,
  label,
  className,
  onChange,
  readOnly,
  disabled,
  invalid,
  error,
  ariaLabel,
}) => {
  const handleOnChangeCheckRadio = (event: BaseSyntheticEvent) => {
    const { id: currentTargetId } = event.currentTarget

    onChange(currentTargetId)
  }

  return (
    <>
      <div className={`${className} ${s.root}`}>
        <input
          id={id}
          name={name}
          type='radio'
          className={`${s.input} ${invalid ? s.invalid : ''}`}
          onChange={handleOnChangeCheckRadio}
          checked={checked}
          readOnly={readOnly}
          disabled={disabled}
          aria-label={ariaLabel}
        />
        <label className={s.label} htmlFor={id || name}>
          {label}
        </label>
      </div>
      {invalid && <p className={s.errorMessage}>{error}</p>}
    </>
  )
}
