import React from 'react'

export const ArrowDownThird = ({ ...props }) => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M3.2551 7.2551C3.56431 6.94589 4.04818 6.91778 4.38915 7.17077L4.48683 7.2551L12 14.7679L19.5132 7.2551C19.8224 6.94589 20.3062 6.91778 20.6472 7.17077L20.7449 7.2551C21.0541 7.56431 21.0822 8.04818 20.8292 8.38915L20.7449 8.48683L12.6159 16.6159C12.3067 16.9251 11.8228 16.9532 11.4818 16.7002L11.3841 16.6159L3.2551 8.48683C2.91497 8.1467 2.91497 7.59523 3.2551 7.2551Z'
      fill='currentColor'
    />
  </svg>
)
