import { useRouter } from 'next/router'
import { FC, useCallback, useEffect, useState } from 'react'
import cn from 'classnames'
import { Button, CheckRadioField, TextField } from '@components/ui'
import { PRICE_FILTER_VALUES } from '@components/store/utils'
import { useStoreDataContext } from '@contexts/storeDataContext/useStoreDataContext'
import { debounce } from '@utils/helpers'
import s from './Store.module.css'

export type PriceFilterValue = {
  minPrice?: string
  maxPrice?: string
}
interface PriceFilterProps {
  handleSetPriceFilter: (value: PriceFilterValue) => void
  onOptionSelected?: (value: PriceFilterValue) => void
  minPrice: string
  maxPrice: string
  isPriceFilterApplied: boolean
  isMaxPriceInvalid: boolean
  showClearHeader?: boolean
}

type FormatPrice = (arg: {
  amount: number
  currencyFormat: string
  decimalMark: string
  thousandsSeparator: string
  defaultCurrency: string
  currencySymbol: string
}) => string

export const formatPriceFilter: FormatPrice = ({
  currencySymbol,
  amount,
  currencyFormat,
  decimalMark,
  thousandsSeparator,
}) =>
  currencyFormat
    .replace('%u', currencySymbol)
    .replace('%c', '')
    .replace(
      '%n',
      amount
        .toFixed(0)
        .replace('.', decimalMark ?? '.')
        .replace(
          /\B(?=(\d{3})+(?!\d))/g,
          thousandsSeparator || (thousandsSeparator === null ? '' : ','),
        ),
    )

export const rangePriceCheckboxLabel = (
  range: string[],
  currencyFormat: string,
  decimalMark: string,
  thousandsSeparator: string,
  defaultCurrency: string,
  currencySymbol: string,
) =>
  Number(range[1])
    ? `${formatPriceFilter({
        amount: Number(range[0]),
        currencyFormat,
        decimalMark,
        thousandsSeparator,
        defaultCurrency,
        currencySymbol,
      })} - ${formatPriceFilter({
        amount: Number(range[1]),
        currencyFormat,
        decimalMark,
        thousandsSeparator,
        defaultCurrency,
        currencySymbol,
      })}`
    : `${formatPriceFilter({
        amount: Number(range[0]),
        currencyFormat,
        decimalMark,
        thousandsSeparator,
        defaultCurrency,
        currencySymbol,
      })}+`

export const PriceFilter: FC<PriceFilterProps> = ({
  isPriceFilterApplied,
  minPrice,
  maxPrice,
  handleSetPriceFilter,
  isMaxPriceInvalid,
  showClearHeader,
  onOptionSelected,
}) => {
  const { data } = useStoreDataContext()
  const currency = data?.attributes?.default_currency || 'USD'
  const router = useRouter()
  const [inputMinPrice, setInputMinPrice] = useState(minPrice)
  const [inputMaxPrice, setInputMaxPrice] = useState(maxPrice)

  const {
    data: {
      attributes: {
        currency_format: currencyFormat,
        decimal_mark: decimalMark,
        thousands_separator: thousandsSeparator,
        default_currency: defaultCurrency,
        currency_symbol: currencySymbol,
      },
    },
  } = useStoreDataContext()

  const onResetPriceFilter = () => {
    handleSetPriceFilter({
      maxPrice: '',
      minPrice: '',
    })
  }

  useEffect(() => {
    setInputMaxPrice(maxPrice)
    setInputMinPrice(minPrice)
  }, [minPrice, maxPrice])

  const debouncedPriceFilter = useCallback(
    debounce(handleSetPriceFilter, 300),
    [],
  )

  const onChangeMinPrice = (value: string) => {
    const updatedMinPrice =
      /^\d+$/.test(value) &&
      (maxPrice ? Number(value) < Number(maxPrice) : true)
        ? value
        : ''

    setInputMinPrice(updatedMinPrice)
    debouncedPriceFilter({ minPrice: updatedMinPrice, maxPrice })
  }

  const onChangeMaxPrice = (value: string) => {
    const updatedMaxPrice = /^\d+$/.test(value) ? value : ''

    setInputMaxPrice(updatedMaxPrice)
    debouncedPriceFilter({ minPrice, maxPrice: updatedMaxPrice })
  }

  const onPriceRangeChange = (value: string) => {
    const range = value.split('-')

    if (onOptionSelected) {
      onOptionSelected({ minPrice: range[0], maxPrice: range[1] })
    } else {
      handleSetPriceFilter({ minPrice: range[0], maxPrice: range[1] })
    }
    router.query.page = '1'
    router.push(router, undefined, { shallow: true })
  }

  return (
    <>
      {showClearHeader ? (
        <div className='w-full flex justify-between items-baseline mb-4'>
          <h3 className='font-bold text-base'>Filter by Price</h3>
          <Button
            variant='clear'
            className='!text-clearFilter'
            onClick={onResetPriceFilter}
            disabled={!isPriceFilterApplied}
          >
            Clear
          </Button>
        </div>
      ) : null}
      <div className={s.filterByPrice}>
        <TextField
          name='minPrice'
          value={inputMinPrice}
          label={`${currencySymbol} min`}
          onChange={onChangeMinPrice}
        />
        <span>to</span>
        <TextField
          name='maxPrice'
          value={inputMaxPrice}
          label={`${currencySymbol} max`}
          onChange={onChangeMaxPrice}
          invalid={isMaxPriceInvalid}
        />
      </div>
      {PRICE_FILTER_VALUES.map(range => (
        <CheckRadioField
          key={`${currency}${range.join('-')}`}
          id={range.join('-')}
          className={s.radio}
          label={
            <span
              className={cn({
                'text-brand': range[0] === minPrice && range[1] === maxPrice,
              })}
            >
              {rangePriceCheckboxLabel(
                range,
                currencyFormat,
                decimalMark,
                thousandsSeparator,
                defaultCurrency,
                currencySymbol,
              )}
            </span>
          }
          readOnly
          checked={range[0] === minPrice && range[1] === maxPrice}
          onChange={onPriceRangeChange}
        />
      ))}
    </>
  )
}
