import { FC } from 'react'
import { useRouter } from 'next/router'
import cn from 'classnames'

const NO_PRODUCTS = 'There are no products that match the selected filters.'

interface InfoHeaderProps {
  isLoading: boolean
  currentCategoryName: string
  productsPerPage: number
  productsTotalCount: number
  currentPage: number
  totalPages: number
  isHomeBrand?: boolean
}

export const StoreInfoHeader: FC<InfoHeaderProps> = ({
  isLoading,
  currentCategoryName,
  productsPerPage,
  productsTotalCount,
  currentPage,
  totalPages,
  isHomeBrand,
}) => {
  const router = useRouter()
  const {
    isReady,
    query: { q },
  } = router

  const loadingText = q ? (
    <>
      Searching for: "<strong>{q}</strong>"
    </>
  ) : (
    'Loading...'
  )

  const showText = () => {
    if (currentPage === 1) return `${currentPage} - ${productsPerPage}`
    if (currentPage === totalPages)
      return `${
        productsTotalCount - (productsPerPage - 1)
      } - ${productsTotalCount}`
    const start = (currentPage - 1) * productsPerPage + 1
    const end = start + productsPerPage - 1

    return `${start} - ${end}`
  }

  const SearchingResultText = (
    <>
      <span
        className={cn('animated', {
          fadeIn: productsPerPage > 0,
          hidden: productsPerPage <= 0,
          '!mb-6 md:!mb-0': isHomeBrand,
        })}
      >
        Showing: <strong>{showText()}</strong>&nbsp;
        {q ? (
          <>
            for "<strong>{q}</strong>"
          </>
        ) : (
          <>
            out of <strong>{productsTotalCount}</strong>
          </>
        )}
      </span>
      <span className={`animated ${!productsPerPage ? 'fadeIn' : 'hidden'}`}>
        {q ? (
          <>
            There are no products that match "<strong>{q}</strong>"
          </>
        ) : (
          NO_PRODUCTS
        )}
      </span>
    </>
  )

  const productCountRenderer = () => {
    if (isLoading) return loadingText
    if (q || currentCategoryName || productsTotalCount > 0)
      return SearchingResultText

    return NO_PRODUCTS
  }

  return (
    <div className='text-sm inline-flex items-center max-w-[95vw] sm:max-w-[55%] md:max-w-[65%] mt-5 sm:mt-0 left-0'>
      {isReady ? productCountRenderer() : 'Loading...'}
    </div>
  )
}
