import ReactPaginate from 'react-paginate'
import { useRouter } from 'next/router'
import { Product } from '@commerce/types/product'
import { ProductCard } from '@components/product'
import { Skeleton } from '@components/ui'
import rangeMap from '@lib/range-map'
import { MetaInfo, NormalizedMenuTaxon } from '@models'
import cn from 'classnames'
import { useStoreDataContext } from '@contexts/storeDataContext/useStoreDataContext'
import s from './Store.module.css'

interface ProductsMetaInfo extends MetaInfo {
  totalCount: number
}

interface IProductsGrid {
  products?: Product[]
  isLoading: boolean
  productsMeta: ProductsMetaInfo
  currentCategory?: NormalizedMenuTaxon | null
  nextLabel: JSX.Element
  prevLabel: JSX.Element
  onPageChange: (paginateData: { selected: number }) => void
}

export const ProductsGrid = ({
  isLoading,
  products,
  productsMeta,
  nextLabel,
  prevLabel,
  onPageChange,
}: IProductsGrid) => {
  const {
    query: { page },
  } = useRouter()

  const {
    data: {
      attributes: { is_home_brand: isHomeBrand },
    },
  } = useStoreDataContext()

  return !isLoading ? (
    <>
      <div
        className={cn(
          'grid gap-x-6 gap-y-1 md:gap-y-8 grid-cols-1 sm:grid-cols-2  min-h-[400px]',
          { 'md:grid-cols-4': !isHomeBrand, 'md:grid-cols-3': isHomeBrand },
        )}
      >
        {products?.map((product: Product) => (
          <ProductCard
            key={product.id}
            className='animated fadeIn'
            product={product}
            imgProps={{
              width: '100%',
              height: 240,
            }}
          />
        ))}
      </div>
      {productsMeta.totalPages > 1 && (
        <ReactPaginate
          className={cn(s.pagination, {
            [s.isHomeBrandPagination]: isHomeBrand,
          })}
          breakClassName={s.empty}
          activeClassName={s.selected}
          disabledClassName={s.disabled}
          nextLabel={nextLabel}
          previousLabel={prevLabel}
          pageRangeDisplayed={3}
          marginPagesDisplayed={1}
          onPageChange={onPageChange}
          pageCount={productsMeta.totalPages}
          forcePage={page ? Number(page) - 1 : 0}
        />
      )}
    </>
  ) : (
    <div className='flex w-products flex-row flex-wrap justify-start items-start -mx-2.5'>
      {rangeMap(12, i => (
        <Skeleton key={i} />
      ))}
    </div>
  )
}
