export const SortIcon = ({ ...props }) => (
  <svg
    width='24'
    height='38'
    viewBox='0 0 24 38'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <g clipPath='url(#clip0_8244_1731)'>
      <path
        d='M7.41 15.41L12 10.83L16.59 15.41L18 14L12 8L6 14L7.41 15.41Z'
        fill='#898984'
      />
    </g>
    <g clipPath='url(#clip1_8244_1731)'>
      <path
        d='M7.41 22.59L12 27.17L16.59 22.59L18 24L12 30L6 24L7.41 22.59Z'
        fill='#898984'
      />
    </g>
    <defs>
      <clipPath id='clip0_8244_1731'>
        <rect
          width='24'
          height='24'
          fill='white'
          transform='matrix(0 1 -1 0 24 0)'
        />
      </clipPath>
      <clipPath id='clip1_8244_1731'>
        <rect
          width='24'
          height='24'
          fill='white'
          transform='matrix(0 1 -1 0 24 14)'
        />
      </clipPath>
    </defs>
  </svg>
)
