import { BaseSyntheticEvent, FC, useRef, useState } from 'react'
import s from './TextField.module.css'

interface TextFieldProps {
  onChange: (val: string) => void
  value?: string
  name: string
  type?: string
  className?: string
  placeholder?: string
  label?: string
  disabled?: boolean
  readOnly?: boolean
  invalid?: boolean
  error?: string
  ariaLabel?: string
}

export const TextField: FC<TextFieldProps> = ({
  onChange,
  value,
  name,
  type = 'text',
  className = 'w-full',
  placeholder,
  label,
  disabled = false,
  readOnly = false,
  invalid,
  error,
  ariaLabel,
}) => {
  const [isFocused, setIsFocused] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null)
  const handleOnChangeInput = (event: BaseSyntheticEvent) => {
    const { value: currentInputValue } = event.currentTarget

    onChange(currentInputValue)
  }
  const onInputFocus = () => {
    setIsFocused(true)
  }
  const onInputBlur = () => {
    setIsFocused(false)
  }

  return (
    <>
      <div
        className={`
          ${className}${
          isFocused || inputRef.current?.value || value ? ' active' : ''
        }
          ${label ? ' floatLabelWrapper' : ''}
          ${invalid ? ' invalid' : ''}
        `}
        aria-invalid={invalid ? 'true' : 'false'}
      >
        {label && (
          <label
            className={`floatLabel${invalid ? ' invalid' : ''} ${
              s.priceFilterLabel
            }`}
            htmlFor={name}
          >
            {label}
          </label>
        )}
        <input
          className={`${s.root} ${invalid ? s.invalid : ''} ${
            placeholder ? s.placeholder : ''
          }`}
          type={type}
          id={name}
          aria-label={ariaLabel}
          ref={inputRef}
          placeholder={placeholder}
          disabled={disabled}
          readOnly={readOnly}
          value={value}
          onChange={handleOnChangeInput}
          onFocus={onInputFocus}
          onBlur={onInputBlur}
        />
      </div>
      {invalid && (
        <p className={s.errorMessage} data-testid={`${name}-error`}>
          {error}
        </p>
      )}
    </>
  )
}
