import {
  BaseSyntheticEvent,
  FC,
  MutableRefObject,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useRouter } from 'next/router'
import {
  SubcategoriesArrowLeft,
  SubcategoriesArrowRight,
} from '@components/icons'
import { NormalizedMenuTaxon } from '@models'
import { useTaxonsContext } from '@contexts/taxonsContext'
import { isEmptyObject } from '@utils/helpers'
import s from './TaxonSelector.module.css'

interface SubcategoriesSelectorProps {
  category: NormalizedMenuTaxon
}

export const TaxonSelector: FC<SubcategoriesSelectorProps> = ({ category }) => {
  const router = useRouter()
  const refScroll = useRef() as MutableRefObject<HTMLInputElement>
  const taxons = useTaxonsContext()
  const [subtaxons, setSubtaxons] = useState<NormalizedMenuTaxon[]>([])
  const [selectedTaxonId, setSelectedTaxonId] = useState('')
  const [scrollFilter, setScrollFilter] = useState(0)
  const [isEndScroll, setIsEndScroll] = useState(false)

  const handleOnClickArrow = (event: BaseSyntheticEvent) => {
    const { name } = event.currentTarget
    const shift = refScroll.current.offsetWidth / 3

    name === 'right'
      ? (refScroll.current.scrollLeft += shift)
      : (refScroll.current.scrollLeft -= shift)
  }

  const handleOnScroll = () => {
    setScrollFilter(refScroll.current.scrollLeft)
    handleIsEndScroll()
  }

  const handleIsEndScroll = () => {
    refScroll.current &&
      setIsEndScroll(
        refScroll.current.scrollWidth - refScroll.current.scrollLeft <=
          refScroll.current.offsetWidth + 1,
      )
  }

  const onClickTaxon = (event: BaseSyntheticEvent) => {
    const { id } = event.target
    const url = id ? `${id}` : `${category.href}`

    router.push(url).then()
  }

  useEffect(() => {
    setSelectedTaxonId('')
  }, [category?.id])

  useEffect(() => {
    const currentTaxon = taxons.find(
      (taxon: NormalizedMenuTaxon) => taxon.id === category.id,
    )

    if (!isEmptyObject(currentTaxon)) {
      setSubtaxons(currentTaxon.dropDownItems as NormalizedMenuTaxon[])
    }
  }, [category.id, taxons])

  useEffect(() => {
    const selectedTaxon = subtaxons.find(
      el => el.href.split('/').slice(-1)[0] === selectedTaxonId,
    )
    const selectedTaxonElement = document.getElementById(
      selectedTaxon?.href || '',
    )

    const positionOfSelectedElement =
      selectedTaxonElement?.getBoundingClientRect()

    if (
      refScroll.current &&
      selectedTaxonElement &&
      positionOfSelectedElement &&
      (selectedTaxonElement?.getBoundingClientRect().left <
        refScroll.current?.getBoundingClientRect().left ||
        selectedTaxonElement?.getBoundingClientRect().right >
          refScroll.current?.getBoundingClientRect().right)
    ) {
      refScroll.current.scroll({
        left: selectedTaxonElement.offsetLeft,
      })
    }

    if (refScroll.current && !selectedTaxonElement) {
      refScroll.current.scroll({
        left: 0,
      })
    }
  }, [selectedTaxonId, subtaxons])

  useEffect(() => {
    const splittedPath = router.asPath.split('?')[0].split('/')

    setSelectedTaxonId(splittedPath.slice(-1)[0] || '')
  }, [router.asPath])

  useEffect(() => {
    const node = refScroll.current

    if (node) {
      refScroll.current.addEventListener('scroll', handleOnScroll, {
        passive: true,
      })
    }

    return () => {
      node.removeEventListener('scroll', handleOnScroll)
    }
  }, [refScroll])

  return (
    <div className={s.root}>
      <div className={s.arrowWrap}>
        {scrollFilter !== 0 && (
          <button
            type='button'
            name='left'
            aria-label='scroll category selector left'
            onClick={handleOnClickArrow}
            className={s.arrowButton}
          >
            <SubcategoriesArrowLeft className='text-brand' />
          </button>
        )}
      </div>
      <div ref={refScroll} className={s.buttons}>
        <button
          type='button'
          className={`${s.item} ${
            category.href.split('/').slice(-1)[0] === selectedTaxonId &&
            s.activeItem
          }`}
          onClick={onClickTaxon}
        >
          All
        </button>
        {subtaxons.map(subtaxon => (
          <button
            key={subtaxon.id}
            id={subtaxon.href}
            type='button'
            className={`${s.item} ${
              selectedTaxonId === subtaxon.href.split('/').slice(-1)[0] &&
              s.activeItem
            }`}
            onClick={onClickTaxon}
          >
            {subtaxon.name}
          </button>
        ))}
      </div>
      <div className={s.arrowWrap}>
        {!isEndScroll &&
          refScroll?.current?.scrollWidth > refScroll?.current?.clientWidth && (
            <button
              type='button'
              name='right'
              aria-label='scroll category selector right'
              onClick={handleOnClickArrow}
              className={s.arrowButton}
            >
              <SubcategoriesArrowRight className='text-brand' />
            </button>
          )}
      </div>
    </div>
  )
}
