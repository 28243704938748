import { Product } from '@commerce/types/product'
import { PriceFilterValue } from './PriceFilter'

export interface StoreState {
  products: Product[]
  productsMeta: {
    perPage: number
    currentPage: number
    totalPages: number
    totalCount: number
  }
  brandsFilter: string[]
  priceFilter: PriceFilterValue
  productsSort: string
  brandsSearchString: string | null
}

export const initialStoreState: StoreState = {
  products: [],
  productsMeta: {
    perPage: 0,
    currentPage: 1,
    totalPages: 0,
    totalCount: 0,
  },
  brandsFilter: [],
  priceFilter: {
    minPrice: '',
    maxPrice: '',
  },
  productsSort: '',
  brandsSearchString: null,
}

export enum StoreActionTypes {
  SAVE_PRODUCTS = 'SAVE_PRODUCTS',
  CHANGE_PRODUCT_SORT = 'CHANGE_PRODUCT_SORT',
  CLEAR_ALL_FILTERS = 'CLEAR_ALL_FILTERS',
  SET_PRICE_FILTER = 'SET_PRICE_FILTER',
  SET_BRANDS_FILTER = 'SET_BRANDS_FILTER',
  SET_BRANDS_SEARCH = 'SET_BRANDS_SEARCH',
}

export const storeReducer = (
  state: StoreState,
  action: { type: StoreActionTypes; payload: Partial<StoreState> },
): StoreState => {
  const { type, payload } = action

  switch (type) {
    case StoreActionTypes.SAVE_PRODUCTS: {
      return {
        ...state,
        products: payload.products || [],
        productsMeta: payload.productsMeta || state.productsMeta,
      }
    }
    case StoreActionTypes.CHANGE_PRODUCT_SORT: {
      return {
        ...state,
        productsSort: payload.productsSort || '',
      }
    }
    case StoreActionTypes.CLEAR_ALL_FILTERS: {
      return {
        ...state,
        brandsFilter: [],
        priceFilter: {
          minPrice: '',
          maxPrice: '',
        },
        brandsSearchString: null,
      }
    }
    case StoreActionTypes.SET_PRICE_FILTER: {
      return {
        ...state,
        priceFilter: payload.priceFilter || {},
      }
    }
    case StoreActionTypes.SET_BRANDS_FILTER: {
      return {
        ...state,
        brandsFilter: payload.brandsFilter || [],
      }
    }
    case StoreActionTypes.SET_BRANDS_SEARCH: {
      return {
        ...state,
        brandsSearchString: payload.brandsSearchString || '',
      }
    }
    default: {
      return state
    }
  }

  return state
}
