import cn from 'classnames'
import { FC, MutableRefObject, useRef, useState } from 'react'
import ClickOutside from '@lib/click-outside'
import { ArrowDown } from '@components/icons'
import { ArrowDownSecond } from '@components/iconsSetTwo'
import { ArrowDownThird } from '@components/iconsSetThree'
import { Icon } from '@components/common'
import { ArrowDownIconCopperStudio } from '@components/iconSetCopperStudio/ArrowDownIconCopperStudio'
import s from '@components/common/SortDropdownMenu/SortDropdownMenu.module.css'
import { PriceFilter, PriceFilterValue } from '../PriceFilter'

interface SortDropdownMenuProps {
  priceFilter: PriceFilterValue
  handleSetPriceFilter: (value: PriceFilterValue) => void
  isMaxPriceInvalid: boolean
  disabled?: boolean
}

export const FilterByPrice: FC<SortDropdownMenuProps> = ({
  priceFilter,
  isMaxPriceInvalid,
  handleSetPriceFilter,
  disabled,
}) => {
  const [display, setDisplay] = useState(false)
  const ref = useRef() as MutableRefObject<HTMLDivElement>
  const isPriceFilterApplied = [
    priceFilter.maxPrice,
    priceFilter.minPrice,
  ].some(price => price)

  const onOptionSelected = (value: PriceFilterValue) => {
    setDisplay(false)
    handleSetPriceFilter(value)
  }

  return (
    <div
      className={cn(
        'hidden sm:flex sm:items-center sm:justify-between text-sm z-30 mb-2 xs:sm-0 w-[222px] ',
      )}
    >
      <ClickOutside active={display} onClick={() => setDisplay(false)}>
        <div className='flex flex-col relative sm:flex-1 h-12'>
          <button
            type='button'
            disabled={disabled}
            onClick={() => {
              setDisplay(!display)
            }}
            aria-label='Menu'
            className={cn(
              'flex justify-between h-14  items-center sm:border rounded-brand sm:w-full px-8 py-3  sm:font-normal sm:text-base sm:gap-x-7 transition ease-in-out duration-150 sm:pl-3.5 sm:pr-0 border-sortFilterColor border-2 text-lg font-bold text-sortFilterColor',
              s.sortDropdownBtn,
            )}
            id='options-menu'
            aria-haspopup='true'
            aria-expanded='true'
          >
            <div className='sm:inline-block hidden whitespace-nowrap'>
              Filter by Price {isPriceFilterApplied ? '(1)' : ''}
            </div>
            <div
              className={cn(
                'h-12 w-12 border-l border-base-border  items-center justify-center text-grayText  sm:flex hidden',
              )}
            >
              <Icon
                set1={
                  <ArrowDown
                    className='text-grayText'
                    style={{ transform: `rotate(${display ? 180 : 0}deg)` }}
                  />
                }
                set2={
                  <ArrowDownSecond
                    className='h-3 w-3 my-auto mr-1 text-black'
                    style={{ transform: `rotate(${display ? 180 : 0}deg)` }}
                  />
                }
                set3={
                  <ArrowDownThird
                    className='my-auto text-black'
                    style={{ transform: `rotate(${display ? 180 : 0}deg)` }}
                  />
                }
                setNinelife={
                  <ArrowDown
                    className='text-grayText'
                    style={{ transform: `rotate(${display ? 180 : 0}deg)` }}
                  />
                }
                setNinefit={
                  <ArrowDown
                    className='text-grayText'
                    style={{ transform: `rotate(${display ? 180 : 0}deg)` }}
                  />
                }
                setCopperStudio={
                  <ArrowDownIconCopperStudio
                    className='text-grayText'
                    style={{ transform: `rotate(${display ? 180 : 0}deg)` }}
                  />
                }
              />
            </div>
          </button>

          {display && (
            <div className={cn(s.sortDropdownMenu, '!p-5')} ref={ref}>
              <PriceFilter
                minPrice={priceFilter.minPrice || ''}
                maxPrice={priceFilter.maxPrice || ''}
                handleSetPriceFilter={handleSetPriceFilter}
                onOptionSelected={onOptionSelected}
                isPriceFilterApplied={isPriceFilterApplied}
                isMaxPriceInvalid={isMaxPriceInvalid}
              />
            </div>
          )}
        </div>
      </ClickOutside>
    </div>
  )
}
