import { Icon } from '@components/common'
import { FilterIcon } from '@components/icons/FilterIcon'
import s from './Store.module.css'

interface IFilterButtonProps {
  label: string
  onClick: () => void
}

export const FilterButton = ({ label, onClick }: IFilterButtonProps) => (
  <button className={s.filtersButton} type='button' onClick={onClick}>
    <Icon
      set1={<FilterIcon />}
      set2={<FilterIcon />}
      set3={<FilterIcon />}
      setNinelife={<FilterIcon />}
      setNinefit={<FilterIcon />}
    />
    {label}
  </button>
)
