import { FC, useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import cn from 'classnames'
import s from './Store.module.css'

interface StoreHeaderProps {
  currentCategoryName: string
  currentCollectionName: string
  isHomeBrand?: boolean
  description?: string
}

export const StoreHeader: FC<StoreHeaderProps> = ({
  currentCategoryName,
  currentCollectionName,
  isHomeBrand,
  description,
}) => {
  const router = useRouter()
  const { isReady, asPath } = router

  const [header, setHeader] = useState('All Categories')

  const isBestSeller = asPath.split('?')[0].split('/')[1] === 'bestsellers'

  useEffect(() => {
    if (isReady) {
      if (currentCategoryName) setHeader(currentCategoryName)
      else if (currentCollectionName) setHeader(currentCollectionName)
      else if (isBestSeller && !isHomeBrand) setHeader('Best Sellers')
      else if (isBestSeller && isHomeBrand) setHeader('Trending This Month')
      else setHeader('All Categories')
    }
  }, [
    isReady,
    currentCategoryName,
    currentCollectionName,
    isBestSeller,
    isHomeBrand,
  ])

  return (
    <>
      <h1
        className={cn(s.productsHeader, {
          'justify-center w-full uppercase text-center': isHomeBrand,
        })}
      >
        {header}
      </h1>
      {description ? (
        <h2
          dangerouslySetInnerHTML={{ __html: description }}
          className='text-center font-bold mb-14 text-[#717171]'
        />
      ) : null}
    </>
  )
}
