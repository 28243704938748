export const SubcategoriesArrowRight = ({ ...props }) => (
  <svg
    width='16'
    height='8'
    viewBox='0 0 16 8'
    fill='currentColor'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path d='M12.01 3H0V5H12.01V8L16 4L12.01 0V3Z' />
  </svg>
)
